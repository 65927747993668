.white {
  color: #ffffff;
}

.orange {
  color: #ff3100;
}

.dark {
  color: #212529;
}

.whitespace {
  margin-top: 50px;
}

.left {
  text-align: left;
}

.title {
  margin-bottom: 20px !important;
}

.showcase {
  margin-top: 50px;
}

.center {
  margin: 0 auto;
}

.MainContainerImg a {
  text-decoration: none;
  color: #ffffff !important;
}

.MainContainerImg .list-group-item {
  background-color: transparent;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.MainContainerImg button {
  margin: 20px 0;
}

.footer {
  margin-top: 20px;
  padding-bottom: 20px;
}

.footer .nav {
  margin-bottom: 10px;
}

.footer .nav-link {
  padding: 0.5rem 0.5rem;
}

.footer a {
  color: #5e5e5e;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
}

.footer a:hover {
  color: #1e1e1e;
}

.footer p {
  font-size: 12px;
  color: #5e5e5e;
}

.footer svg {
  color: tomato;
}

.heart svg {
  color: tomato;
}

@media (max-width: 979px) {
  .col-6 {
    width: 100%;
    margin-top: 50px;
  }

  .showcase {
    margin-top: 0;
  }

  .img-sm {
    width: 90%;
  }

  .video {
    width: 100% !important;
  }

  .dept-btn-cont {
    width: 80%;
  }
}

@media (min-width: 979px) {
  .topbtn {
    display: block;
    color: white;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.MuiFormLabel-root {
  color: #000 !important;
  font-weight: 600 !important;
}

.MuiFormLabel-root span {
  color: rgb(243, 12, 12) !important;
  font-weight: 600 !important;
}

.promo-text {
  -webkit-text-decoration-line: line-through;
  /* Safari */
  text-decoration-line: line-through;
}

.MuiPaper-root.MuiAccordion-root {
  background-color: #cc3333;
  color: white;
  padding: 0.35rem 0;
  margin-bottom: 0.25rem;
}

.MuiPaper-root.MuiAccordion-root.bg-white {
  background-color: white !important;
  color: #161616 !important;
  padding: 0.35rem 0;
  margin-bottom: 0.25rem;
  align-items: stretch !important;
}

.MuiTypography-h5 {
  font-size: 1.125rem;
  margin-bottom: auto !important;
}

.MuiAppBar-positionFixed {
  background-color: rgba (0, 0, 0, 0.87);
  color: white !important;
}

@media (max-width: 600px) {
  .MuiToolbar-regular {
    padding-top: 0.25rem;
  }

  .MuiIconButton-edgeStart {
    margin-top: -1rem;
  }

  .MuiCardMedia-root {
    padding: 0.5rem;
  }
}

.MuiAccordionSummary-expandIconWrapper {
  color: rgba(254, 254, 254, 0.99) !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #161616 !important;
}

@media (min-width: 601px) {
  .MuiCardMedia-root {
    padding: 1rem;
  }
  .MuiPaper-root.MuiCard-root {
    align-items: start !important;
  }
  .MuiPaper-root.MuiCard-root.bg-white {
    align-items: stretch !important;
  }
}

.MuiTypography-caption {
  font-size: 1rem !important;
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}

.MuiTypography-body1 {
  min-width: 75px;
}

@media (max-width: 360px) {
  .h1,
  h1 {
    font-size: calc(1.175rem + 1.5vw) !important;
  }
}

@media (max-width: 575px) {
  .MuiPaper-root.MuiCard-root {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .OrderHeader .card {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ContactHeader {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .PhonerightImg {
    background-size: auto 80%;
  }

  .ContactHeader {
    height: auto;
  }
}

.MuiImageList-root {
  width: auto !important;
}

.MuiCardActions-root {
  float: right !important;
}

.css-yk16xz-control {
  font-size: large !important;
}

.DateInput_input {
  font-size: large !important;
}

.MuiContainer-root {
  margin-top: 2rem;
}

.btn-outline-danger {
  color: #fff !important;
  border-color: #cc3333 !important;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #cc3333 !important;
  border-color: #cc3333 !important;
}

.btn-outline-danger:active {
  color: #fff !important;
  background-color: #cc3333 !important;
  border-color: #cc3333 !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1.75rem !important;
}

.MuiBox-root {
  font-size: 1.125rem;
  line-height: normal;
}

.MuiButton-label {
  width: max-content !important;
}

.MuiAppBar-positionFixed {
  position: fixed !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 10px !important;
  }
}

.MuiToolbar-regular {
  min-height: 10px !important;
}

@media (max-width: 991px) {
  .navbar-dark .navbar-toggler {
    margin-left: 24px;
  }

  .navbar-collapse {
    padding-left: 24px;
    padding-top: 24px;
  }
}

.navbar-brand {
  padding-left: 24px;
  padding-right: 30px;
}

.App {
  text-align: center;
}

.SL-logo {
  height: 20vmin;
  pointer-events: none;
  margin-left: auto;
  margin-right: auto;
}

.SL-logo02 {
  height: 20vmin;
  pointer-events: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.Default-logo {
  height: 20vmin;
  pointer-events: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .SL-logo {
    /*    animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.silogo {
  width: 110px;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gold {
  color: #ff9933;
}

.pac-container {
  z-index: 99999;
}

.gm-style-iw-a {
  z-index: -1 !important;
}

.swal2-container {
  z-index: 999999 !important;
}

#modalAlert .MuiTypography-h6 {
  font-size: 1.75rem !important;
}

#modalAlert .MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.74) !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0) !important;
}

.MuiPaper-root.MuiCard-root {
  box-shadow: none !important;
}

.MuiPaper-root.MuiCard-root.bg-white {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0) !important;
}

.color-scheme {
  color: black !important;
}

.none-link {
  text-decoration: none !important;
}

.red {
  padding: 8px !important;
  max-width: fit-content;
  border-radius: 4px;
}

@media (max-width: 500px) {
  #checkoutTable th img {
    display: none !important;
  }

  .navbar-brand {
    padding-left: 0px;
    padding-right: 84px;
  }
}

@media (max-width: 375px) {
  .navbar-brand {
    padding-left: 0px;
    padding-right: 44px;
  }
}

@media (min-width: 651px) and (max-width: 799px) {
  #checkoutTable th img {
    width: 100% !important;
  }
}

@media (max-width: 319px) {
  #checkoutTable .MuiTableCell-root {
    display: contents;
    text-align: center;
  }

  #checkoutTable .MuiGrid-justify-content-xs-flex-end {
    justify-content: center;
  }

  #checkoutTable h5 {
    padding-top: 10px;
  }
}

@media (max-width: 319px) {
  #checkoutTotal .MuiTableCell-root {
    display: contents;
    text-align: center;
  }

  #checkoutTotal h5 {
    padding-top: 10px;
  }
}

#checkoutTotal .MuiButtonBase-root.MuiButton-root {
  min-width: 10px !important;
}

.customBg {
  background: #2b5876;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2b5876, #4e4376);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2b5876, #4e4376);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: rgba(0, 0, 0, 0.74); */
  /* background: #24292f; */
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
}

.eLoyaltyBg .MuiBox-root {
  background: transparent;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #2b5876, #4e4376); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #2b5876, #4e4376); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.formBG .MuiBox-root {
  background: beige;
  padding: 10px;
  border-radius: 5px;
}

.list-group-item ul,
li {
  list-style-type: none;
}

#quickmenu .MuiCardHeader-root {
  padding: 0.5rem !important;
}

#quickmenu .MuiCardMedia-root {
  padding: 0.5rem !important;
}

#quickmenu .MuiCardContent-root {
  padding: 0.5rem !important;
}

#quickmenu .MuiTypography-h5 {
  font-size: 1.5rem !important;
}

.plzero ol,
ul {
  padding-left: 0 !important;
}

.paddingSpecial {
  /* padding-top: 1rem; */
  padding-left: 2rem !important;
}

.partner-title {
  margin: 0 auto;
}

.HomemadeHeader {
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -120px;
}

.SpecialsHeader {
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -120px;
}

.QuickMenuHeader {
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
  padding: 100px 0 20px 0;
  margin-top: -120px;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.privacy .h3,
h3 {
  font-size: calc(1rem + 0.6vw);
}

@media (min-width: 1200px) {
  .privacy .h3,
  h3 {
    font-size: 1.25rem;
  }
}

.CookieConsent {
  width: 100% !important;
  height: 100% !important;
  bottom: 10px !important;
  left: 10px !important;
  max-width: 475px !important;
  max-height: 475px !important;
  justify-content: center !important;
  align-items: center !important;
}

.main-menus:hover div img {
  transform: scale(1.2);
}

.about-right-text {
  /* margin-top: 20px; */
  background: #ff9933;
  box-shadow: 0px 5px 68px 0px rgb(0 21 100 / 10%);
  padding: 15px;
  display: flex;
  margin-bottom: 15px;
}

.core-icon {
  margin-right: 15px;
  text-align: center;
}

.paddingRight15 {
  padding-right: 15px;
}

@media (max-width: 349px) {
  .navbar-brand {
    padding-right: 64px;
  }
}

@media (max-width: 343px) {
  .navbar-brand {
    padding-right: 60px;
  }
}

@media (max-width: 339px) {
  .navbar-brand {
    padding-right: 58px;
  }
}

@media (max-width: 337px) {
  .navbar-brand {
    padding-right: 56px;
  }
}

@media (min-width: 320px) and (max-width: 337px) {
  .navbar-brand {
    padding-right: 56px;
    padding-left: 56px;
  }
}

/* StickyCategoriesNav */
/* RestMenu BlankSpaceTen 451  */

.stickyCategoriesNav {
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%);
  background: #ffffff;
  position: sticky !important;
  z-index: 3;
  padding: 10px;
  margin-bottom: 35px;
}

.stickyCategoriesNav ul {
  display: flex;
  justify-content: stretch;
  margin: 0px 10px;
  gap: 0rem;
  flex-wrap: wrap;
  height: 40px;
  align-items: center;
}

.stickyCategoriesNav li {
  display: flex;
  list-style: none;
  gap: 0.45rem;
}

.stickyCategoriesNav a {
  color: #333333 !important;
  cursor: pointer;
  padding: 5px 0px !important;
  margin: 5px;
}

.stickyCategoriesNav a.active {
  font-weight: bold;
  border-bottom: 3px solid #cc3333 !important;
  text-decoration: none;
}

.stickyCategoriesNav a:hover {
  font-weight: bold;
  border-bottom: 3px solid #cc3333;
}

/* HEADER STICKY */
.navBarKeto {
  position: sticky !important;
  top: 0px !important;
  background: #0f9453;
  z-index: 999;
}

.SingleDatePicker_picker {
  z-index: 19 !important;
}

.slider-caption {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  line-height: 1.1 !important;
}

.slider-caption p {
  font-size: larger;
}

.couponcode {
  width: 30%;
  padding: 14px;
  border: 1px solid #eaeaea;
  font-size: 1rem;
}

.checkcell03 .MuiBox-root {
  border: 1px solid #000;
  background-color: #000;
}

.bottomlanding {
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
  background-attachment: fixed;
}

.bottomlanding .f-logo {
  padding-bottom: 20px;
}

.eKioskCategoriesNav {
  box-shadow: 0 0.2rem 1rem 1px rgb(0 0 0 / 15%);
  background: #ffffff;
  z-index: 3;
  padding: 10px;
  margin-bottom: 35px;
}

.eKioskCategoriesNav ul {
  display: flex;
  justify-content: stretch;
  margin: 0px 10px;
  gap: 1rem;
  flex-wrap: wrap;
  height: 40px;
  align-items: center;
}

.eKioskCategoriesNav li {
  display: flex;
  list-style: none;
}

.eKioskCategoriesNav a {
  /* color: #333333 !important; */
  cursor: pointer;
  /* padding: 5px 0px !important;
	margin: 5px; */
}

.eKioskCategoriesNav a.active {
  font-weight: bold;
  border-bottom: 3px solid whitesmoke;
  text-decoration: none;
}

.eKioskCategoriesNav a:hover {
  font-weight: bold;
  border-bottom: 3px solid whitesmoke;
}

.eKioskCategoriesNav {
  position: sticky !important;
  top: 0px !important;
}

.eKioskCategoriesNav .MuiSvgIcon-root {
  width: 4.5em;
  height: 4.5em;
}

.MuiPaper-root.MuiAppBar-root.MuiAppBar-colorPrimary.eKioskCategoriesNav {
  background-color: #b8af93;
}

.eKioskbtn .MuiButton-label {
  padding: 1.5rem;
}

.eKioskAmount .MuiInputLabel-shrink {
  transform: translate(0, -7.5px) scale(1.5) !important;
}

.eKioskInput .MuiInputBase-input {
  font-size: 1.5rem;
}

.eKioskAmount .MuiInputBase-input {
  font-size: 1.75rem;
}

.NormalAmount .MuiInputLabel-shrink {
  transform: translate(0, -7.5px) scale(1.125) !important;
}

.NormalInput .MuiInputBase-input {
  font-size: 1.125rem !important;
}

.NormalAmount .MuiInputBase-input {
  font-size: 1.25rem !important;
}

.swal2-confirm {
  font-size: 1.5em !important;
}

.svgeKiosk .MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}

@media (min-width: 1366px) {
  .eKioskRestaurantNav {
    top: 110px !important;
  }
}

.welcomeCustom {
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.Welcome {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

@media (min-width: 1200px) {
  .eKioskRestaurantNav {
    top: 100px !important;
  }
}

@media (max-width: 1199px) {
  .eKioskRestaurantNav {
    top: 90px !important;
  }
}

@media (max-width: 820px) {
  .foodCardPlusSign {
    left: 56px !important;
  }
}

@media (max-width: 768px) {
  .foodCardPlusSign {
    left: 52px !important;
  }
}

@media (max-width: 425px) {
  .foodCardPlusSign {
    top: 104px !important;
    left: 104px !important;
  }
}

.hg-theme-default .hg-button {
  height: 60px !important;
}

.hg-theme-default {
  font-size: 1.5rem !important;
}

.KioskMods .MuiInputLabel-root {
  font-size: 1.75rem;
  padding-bottom: 1rem;
}

.KioskMods .MuiTypography-body1 {
  font-size: 1.5rem;
}

.KioskMods .MuiSelect-selectMenu {
  min-height: 2.5em;
}

.KioskMods .MuiMenuItem-root {
  font-size: 1.25rem;
}

.DiscoverList .MuiTabScrollButton-root {
  background-color: #5e5e5e;
  opacity: 40%;
}

.DiscoverList .MuiSvgIcon-fontSizeSmall {
  font-size: 2.75rem !important;
  color: #000;
}

.MuiBadge-colorSecondary {
  background-color: #d0380c !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1.35) translate(50%, -50%) !important;
  transform-origin: 0% 100% !important;
}

.css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
  display: flex !important;
  background-color: #eaeaea;
}
.miniCheckoutLayoutContainer {
  display: block !important;
  width: 100% !important;
}

.miniCheckoutLayoutInner {
  display: flex !important;
  align-items: center !important;
  margin: 20px 5px 0 !important;
  gap: 20px !important;
}

div#checkoutMiniTotalPrice div {
  display: flex !important;
  justify-content: space-between;
  margin: 10px 0px;
}

div#checkoutMiniTotalPrice p {
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 1.2rem !important;
  padding-right: 5px !important;
}

table.checkoutMobileFlexTotalInner {
  width: 100% !important;
}

table.checkoutMobileFlexTotalInner {
  border: 0px !important;
}

.tableOrderList td {
  display: none !important;
}

.tableOrderList tr,
td {
  border: 0px !important;
}

.tableRemoveAdd {
  justify-content: center;
}

th#checkoutItemName {
  padding-left: 20px;
}
@media (max-width: 375px) {
  div#checkoutTotal td,
  .tableOrderList th {
    padding: 0px !important;
    margin: 5px !important;
  }

  .tableOrderList th {
    padding: 0px !important;
    margin: 10px 5px !important;
  }

  .tableRemoveAdd {
    padding-right: 30px !important;
  }

  .miniCheckoutLayoutInner,
  h6 {
    font-size: medium !important;
  }
}

@media (max-width: 425px) {
  .checkoutMobileFlex {
    display: flex !important;
    align-items: center !important;
  }

  .checkoutMobileBorder {
    display: flex !important;
    align-items: center !important;
  }

  .checkoutMobileFlexTotal tr {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    justify-content: space-between;
  }

  .tableRemoveAdd * {
    min-width: 10px !important;
  }

  .amountDueMobile {
    margin-top: 21px !important;
    border-radius: 5px;
  }

  .buttonAddRemovePrice {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .buttonAddRemovePrice h5 {
    text-align: end !important;
  }

  .checkoutMobileBorder h5 {
    width: 100% !important;
  }

  .checkoutMobileBorder {
    margin-top: 0px !important;
  }

  table.checkoutMobileFlexTotalInner {
    margin-top: 25px !important;
  }

  .foodCardPlusSign {
    top: 104px !important;
    left: 104px !important;
  }

  th#checkoutItemName {
    display: block !important;
    width: 100% !important;
  }
}
#LoginCode .font-size-changer {
  height: 0 !important;
}

#LoginCode .font-size-changer-buttons {
  height: 0 !important;
}

.font-size-changer {
  height: 0 !important;
}
@media (max-width: 899px) {
  .font-size-changer {
    height: 0px !important;
  }

  #transparent {
    /*
        background-color: transparent;
        color: white;
*/
    border-color: transparent;
    /*        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;*/
    font-weight: bold;
    display: none;
  }
}
.font-size-changer .font-size-changer-buttons > div {
  float: none !important;
  border: none !important;
  line-height: 1px !important;
  width: 1px !important;
  height: 1px !important;
}
#target .MuiTypography-subtitle1 {
  font-size: large;
  line-height: 1;
  margin-bottom: 0.5rem;
}

#target .MuiTypography-subtitle2 {
  font-size: large;
  line-height: 1.2 !important;
  margin-top: 0.5rem;
}

.MuiTypography-caption {
  line-height: 1.2 !important;
  font-size: large;
}

#target label + .MuiInput-formControl {
  font-size: large;
}

#target .MuiTypography-body1 {
  font-size: large;
  line-height: 1.15;
}

/* #target .MuiButton-label {
  font-size: large;
} */

#target .MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1.125);
}

.modal-footer {
  font-size: x-large !important;
}

#draw .MuiTypography-caption {
  font-size: large !important;
}

#draw .MuiTypography-subtitle2 {
  font-size: large !important;
}

.heroBannerImage {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 200px;
}

.css-yk16xz-control {
  min-height: 48px !important;
}

#nohover {
  background-color: transparent !important;
  border-radius: 0% !important;
}

#nohover .MuiPaper-root-MuiAppBar-root {
  border-radius: 5% !important;
}

/* RECEIPT BUDGE */
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge.css-tzssek-MuiSvgIcon-root {
  color: white;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-black.sticky-top
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  margin-top: 5px;
}

/* .stickyCategoriesNav svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
    color: #CC3333 !important;
} */

.stickyCategoriesNav {
  box-shadow: 0 0.2rem 1rem 1px rgb(0 0 0 / 15%);
  background: #ffffff;
  z-index: 3;
  padding: 10px;
  margin-bottom: 35px;
}

.stickyCategoriesNav ul {
  display: flex;
  justify-content: stretch;
  margin: 0px 10px;
  gap: 2rem;
  flex-wrap: wrap;
  height: 40px;
  align-items: center;
}

.stickyCategoriesNav li {
  display: flex;
  list-style: none;
}

.stickyCategoriesNav a {
  color: #333333 !important;
  cursor: pointer;
  padding: 10px 0px !important;
  margin: 10px;
}

.stickyCategoriesNav a.active {
  font-weight: bold;
  border-bottom: 3px solid #cc3333 !important;
  text-decoration: none;
}

.stickyCategoriesNav a:hover {
  font-weight: bold;
  border-bottom: 3px solid #cc3333;
}

/* HEADER STICKY */
/* nav.navbar.navbar-expand-lg.navbar-dark.bg-black.sticky-top {
    position: sticky !important;
	top: 0px !important;
} */

.footerMenu {
  margin-top: 24px !important;
  padding-top: 28px !important;
}

/* HeaderChanged SL*/
.OrderNow {
  margin-top: 0px;
}

div#logo-center {
  position: sticky !important;
  top: 0 !important;
  margin: 10px 0px !important;
  /* width: fit-content !important;
  width: 200px !important; */
}

div#NewUI-center {
  position: sticky !important;
  margin: 10px 0px !important;
  width: fit-content !important;
  width: 100% !important;
}

div#transparentCustomUser,
div#transparentCustomBal {
  border: 0px !important;
  background: unset !important;
  padding: 5px 0px !important;
}

div#transparentCustomUser div {
  font-size: 18pt !important;
  font-weight: 700 !important;
  font-family: arial !important;
  color: #000000 !important;
}

div#transparentCustomBal h2 {
  font-size: 16pt !important;
  font-weight: 700 !important;
  font-family: arial !important;
  color: #000000 !important;
}

div#headerLogoSticky {
  position: sticky !important;
  top: 0px !important;
  z-index: 10;
  /* background: #ffffff;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important; */
}

.userInfo {
  color: black !important;
  padding: 24px 0px !important;
}

/* CheckOut Icon Left */
/* button#iconLeft {
    background: unset !important;
    color: black;
} */

.BannerImage {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 240px;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* WELCOME PAGE */
.welcomeCustom {
  margin-bottom: 100px !important;
}

.cardAreaMenu img {
  padding: 0px !important;
}

.cardAreaMenu img:hover {
  transform: scale(1.1);
  margin-top: 0px !important;
  padding-top: 0px !important;
  transition: all 1.5s ease 0s;
  object-fit: cover;
  background: white !important;
}

.cardAreaMenu div:hover {
  color: #00296f !important;
}

/* BACK ARROW */
/* [data-testid="LogoutTwoToneIcon"], [data-testid="ArrowBackTwoToneIcon"] {
    font-size: 1.5rem !important;
} */
.welcomeImage {
  overflow: hidden !important;
}

/* DIV ROOT */

div#root {
  background: #f7f7f7 !important;
}

/* BREADCRUMBS */

.breadcrumbs span {
  font-size: 13pt !important;
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}

.breadcrumbs a {
  background: #005cb8;
  color: white !important;
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}

.breadcrumbs a:hover {
  background: #00296f !important;
}

.breadcrumbs a > svg {
  padding: 5px;
  color: white !important;
}

.breadcrumbs ol {
  margin-top: 24px !important;
}

.breadcrumbs li {
  margin-top: 10px;
  list-style-type: none;
}

.breadcrumbs li.MuiBreadcrumbs-separator {
  margin: 0px !important;
  margin-top: 7px !important;
}

b.logoAmount {
  color: #367c2b !important;
}

div#formCheckout fieldset {
  background: #fff;
}

div#formCheckout input {
  z-index: 1 !important;
}

.checkoutBorderBottom td {
  display: none !important;
}

.checkoutMobileFlexTotal td {
  border: 0px !important;
}

.checkoutMobileBorder th {
  border-bottom: 0px !important;
}

.checkoutBorderBottom td {
  display: none !important;
}

.buttonAddRemovePrice th {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}

.buttonAddRemovePrice {
  margin-top: 10px !important;
}

.tableRemoveAdd {
  position: relative !important;
  left: 12px !important;
  top: -5px !important;
}

div#target {
  background: white !important;
}

.miniCheckoutLayoutContainer {
  display: block !important;
  width: 100% !important;
}

.miniCheckoutLayoutInner {
  display: flex !important;
  align-items: center !important;
  margin: 20px 5px 0 !important;
  gap: 20px !important;
}

div#checkoutMiniTotalPrice div {
  display: flex !important;
  justify-content: space-between;
  margin: 10px 0px;
}

div#checkoutMiniTotalPrice p {
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 1.2rem !important;
  padding-right: 5px !important;
}

table.checkoutMobileFlexTotalInner {
  width: 100% !important;
}

table.checkoutMobileFlexTotalInner {
  border: 0px !important;
}

.tableOrderList td {
  display: none !important;
}

.tableOrderList tr,
td {
  border: 0px !important;
}

.tableRemoveAdd {
  justify-content: center;
}

th#checkoutItemName {
  padding-left: 20px;
}

@media (max-width: 375px) {
  div#checkoutTotal td,
  .tableOrderList th {
    padding: 0px !important;
    margin: 5px !important;
  }

  .tableOrderList th {
    padding: 0px !important;
    margin: 10px 5px !important;
  }

  .tableRemoveAdd {
    padding-right: 30px !important;
  }

  .miniCheckoutLayoutInner,
  h6 {
    font-size: medium !important;
  }
}

@media (max-width: 425px) {
  .checkoutMobileFlex {
    display: flex !important;
    align-items: center !important;
  }

  .checkoutMobileBorder {
    display: flex !important;
    align-items: center !important;
  }

  .checkoutMobileFlexTotal tr {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    justify-content: space-between;
  }

  .tableRemoveAdd * {
    min-width: 10px !important;
  }

  .amountDueMobile {
    margin-top: 21px !important;
    border-radius: 5px;
  }

  .buttonAddRemovePrice {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .buttonAddRemovePrice h5 {
    text-align: end !important;
  }

  .checkoutMobileBorder h5 {
    width: 100% !important;
  }

  .checkoutMobileBorder {
    margin-top: 0px !important;
  }

  table.checkoutMobileFlexTotalInner {
    margin-top: 25px !important;
  }

  .foodCardPlusSign {
    top: 104px !important;
    left: 104px !important;
  }

  th#checkoutItemName {
    display: block !important;
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  /* button#nohover {
		margin-top: 0px !important;
		z-index: -1 !important;
	} */
  .tableRemoveAdd {
    position: relative !important;
    left: 12px !important;
    top: -5px !important;
  }
}

/* Seniorliving */

@media (max-width: 767px) {
  /* #transparent {
	position: relative;
	top: 125px !important;
    margin: 0px auto !important;
	min-height: 105px !important;
	} */
  .welcomeCustom {
    margin-bottom: auto !important;
  }

  /* DisplayFLex */
  .LogoMenuHeader div {
    display: flex !important;
    flex-direction: column;
  }

  div#transparentCustomUser,
  div#transparentCustomBal {
    width: 100% !important;
    position: relative;
    top: 10px;
    padding: 0px 20px !important;
    margin: 10px 0px !important;
  }

  .div#transparentCustomBal {
    position: relative !important;
    top: 20px !important;
  }

  div#transparentCustomUser div,
  div#transparentCustomBal h2 {
    display: inline !important;
    font-size: 15pt !important;
  }

  .OrderNow {
    margin-top: 0px !important;
  }

  .stickyCategoriesNav {
    position: sticky !important;
    top: 0px !important;
  }

  .welcomeMenuResponsive {
    display: inline-block !important;
  }

  .backArrowTopMenu {
    margin-left: 16px !important;
  }

  /* div#headerLogoSticky {
    margin-top: 20px !important;
  } */
}

@media (max-width: 599px) {
  .foodCardPlusSign {
    left: 90% !important;
    top: 90% !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .checkoutDrawerRight {
    gap: 30px !important;
  }

  .foodCardPlusSign {
    left: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .foodCardPlusSign {
    left: 60px !important;
  }

  .checkoutMobileBorder {
    margin-top: 0px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .foodCardPlusSign {
    left: 75px !important;
  }
}

.gold {
  color: #cea052 !important;
}

.CenterName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.CenterName:hover {
  color: #00296f !important;
}

@media (max-width: 576px) {
  .MuiTypography-h1 {
    font-size: 5rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .MuiTypography-h1 {
    font-size: 3.5rem !important;
  }
  .welcomeMenuResponsive {
    max-width: 90% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .MuiTypography-h1 {
    font-size: 3rem !important;
  }
  .welcomeMenuResponsive {
    max-width: 80% !important;
  }
  .profileCustom {
    width: 400px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .MuiTypography-h1 {
    font-size: 3rem !important;
  }
  .welcomeMenuResponsive {
    max-width: 70% !important;
  }
  .profileCustom {
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .MuiTypography-h1 {
    font-size: 4.5rem !important;
  }
  .welcomeMenuResponsive {
    max-width: 50% !important;
  }
  .profileCustom {
    width: 400px;
  }
}

.MuiTypography-h1 {
  text-align: center !important;
}

.item-list li {
  display: inline-block;
  position: relative;
}

.item-list li::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 70%;
  background-color: #000;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  font-size: 1.5rem !important;
}
.MuiTypography-body1 {
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}
.MuiButton-root {
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}
.MuiTypography-h5 {
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
  font-weight: 550 !important;
}
.MuiTypography-subtitle1 {
  font-family: Arial, Helvetica, Georgia, "Times New Roman", Times, sans-serif !important;
}

/* General styling for the container holding the price and discount information */
.mb-2 {
  margin-bottom: 0.5rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-base {
  font-size: 1.25rem;
}

input[type="number"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

input[type="number"]:focus {
  border-color: #0056b3;
  outline: none;
}

.discountTag {
  color: #ff5722;
  font-weight: 500;
  background-color: #ef3e3e;
  padding-top: 0.225rem;
  padding-bottom: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.85rem;
  height: 1rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 0.25rem;
}
.originalPriceTag {
  flex-grow: 0;
  flex-shrink: 1;
  color: #a0a0a0;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 1rem !important;
}
.primaryItemDisplay {
  flex-shrink: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}
.flexRowCompact {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  min-width: 0;
  margin-right: 0.25rem;
  gap: 0.25rem;
}
.flexTruncateBaseline {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.origPriceTagFC {
  flex-grow: 0;
  flex-shrink: 1;
  color: #a0a0a0;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 1.125rem !important;
}
.primItemDisplay {
  flex-shrink: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}
